type Command =
    | 'scanReservations'
    | 'scanCars'
    | 'setChipId'
    | 'loginReservation'
    | 'logoutReservation'
    | 'checkCarState'
    | 'reload'
    | 'setOrgKey';

type WrapperMessage = {
    request: Command;
    reservationNumber?: string;
    carNumber?: string;
    chipId?: string;
    userId?: string;
    email?: string;
    name?: string;
    orgKey?: string;
};

type iOSMessageHandler = {
    postMessage: (message: WrapperMessage) => void;
};

export const wrapperBridgeExists = (): boolean => {
    try {
        // @ts-ignore DeleAndroidWrapperBridge created by android wrapper
        if (DeleAndroidWrapperBridge) return true;
    } catch (e) {}

    // console.log('wrapperBridgeExists: ' + !!(window.webkit &&
    //     window.webkit.messageHandlers &&
    //     window.webkit.messageHandlers.deleIosWrapperBridge))

    return !!getDeleIosWrapperBridge();
};

function getDeleIosWrapperBridge(): iOSMessageHandler {
    // @ts-ignore webkit variable created by iOS wrapper
    return window.webkit?.messageHandlers?.deleIosWrapperBridge;
}

export function logInReservation(reservationNumber: string): boolean {
    return wrapperAppFunction({
        request: 'loginReservation',
        reservationNumber: reservationNumber,
    });
}

export function logOutReservation(reservationNumber: string): boolean {
    return wrapperAppFunction({
        request: 'logoutReservation',
        reservationNumber: reservationNumber,
    });
}

export function scanForCars(carNumber: string): boolean {
    return wrapperAppFunction({ request: 'scanCars', carNumber });
}

export function scanForReservations(): boolean {
    return wrapperAppFunction({ request: 'scanReservations' });
}

export function setOrgKey(orgKey: string): boolean {
    return wrapperAppFunction({ request: 'setOrgKey', orgKey });
}

export function identifyUser(
    id: string,
    name: string,
    email: string,
    chipId: string
): boolean {
    return wrapperAppFunction({
        request: 'setChipId',
        userId: id,
        name,
        email,
        chipId,
    });
}

export function checkCarState(reservationNumber: string): boolean {
    return wrapperAppFunction({ request: 'checkCarState', reservationNumber });
}

export function reloadWebView(): boolean {
    return wrapperAppFunction({ request: 'reload' });
}

const wrapperAppFunction = (payLoad: WrapperMessage) => {
    if (getDeleIosWrapperBridge()) {
        try {
            getDeleIosWrapperBridge().postMessage(payLoad);
            return true;
        } catch (e) {
            console.error(`Error when sending ${payLoad} to ios wrapper`);
            //TODO
            // try {
            //     getDeleIosWrapperBridge().postMessage({
            //         error: e,
            //     });
            // } catch (ignored) {}
        }
        return false;
    }

    let android;
    try {
        // @ts-ignore DeleAndroidWrapperBridge should be added by the Android wrapper if it exists
        android = DeleAndroidWrapperBridge;
    } catch (e) {
        return false;
    }

    if (android) {
        try {
            android.postMessage(JSON.stringify(payLoad));
            return true;
        } catch (e) {
            // try {
            //     android.postMessage({ error: e });
            //     return true;
            // } catch (ignored) {}
        }
    }
    return false;
};
